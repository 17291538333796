import form from './form';

const notification = {
    'notification.add.success': `{item} {br}
        has been added successfully`,

    'notification.add.task.success': `Your task has been added to the queue {br}
        Check sidebar notifications for status update`,

    'notification.add-student.success': `Student has been added successfully {br} {br}
        Student username is {username} {br}
        The default password is {username}`,

    'notification.add-student-and-assign.success': `Student has been added successfully {br} {br}
        Student username is {username} {br}
        The default password is {username} {br} {br}
        Student will login at {url}`,

    'notification.add-child-and-assign.success': `Student has been added successfully {br} {br}
        Student username is {username} {br} {br}
        Student will login at {url}`,

    'notification.activate-child.success': `{program} has been activated {br} {br}
        Student will login at {url}`,

    'notification.add.purchase-order.success': `Purchase Order {br}
        has been added successfully`,

    'notification.add.success-quote_sent': `Quote has been sent successfully`,

    'notification.assign-program.activate': `License has been activated successfully`,
    'notification.assign-program.renew': `License has been extended successfully`,
    'notification.assign-program.switch': `Program has been switched successfully`,
    'notification.assign-program.skip': `Student is already using a valid license`,
    'notification.assign-program.should.buy.licenses': `You do not have any program licenses
        to assign to student accounts. {br}
        Purchase Student Program Licenses to continue.`,

    'notification.add.success-invited': `Your invitation for {item} to use Centervention {br}
        has been sent successfully`,

    'notification.update.success': `{item} {br}
        has been updated successfully {br}
        {fields} {countFields, plural, one {is} other {are}} changed`,

    'notification.update.success-without-item': `Data
        has been updated successfully {br}
        {fields} {countFields, plural, one {is} other {are}} changed`,

    'notification.update.profile.success': `Profile has been updated successfully`,

    'notification.update.profile-with-username.success': `Profile has been updated successfully. {br}
        Check your new email. We sent you a link {br}
        to complete your username change. {br}
        The link will expire in 48 hours.`,

    'notification.update.class-code.success': `Class Code has been updated successfully`,

    'notification.update.game-debug-mode': `Game Debug Mode was updated successfully`,

    'notification.update.my-students.success': `Connected students have been updated successfully`,

    'notification.verify.success': `{item} {br}
        has been verified successfully`,

    'notification.convert-account.success': `Account has been converted successfully`,

    'notification.reached-out.success': `User has been marked as reached out`,

    'notification.high-priority.success': `User has been updated`,

    'notification.account-deactivate.success': `Account has been deactivated successfully`,
    'notification.account-rollback-deactivate.success': `Account deactivation has been rolled back successfully`,
    'notification.educator-deactivate.success': `Educator has been deactivated successfully`,
    'notification.educator-deactivate.success-with-licenses': `Educator has been deactivated successfully {br}
        {licenses} {licenses, plural, one {license has} other {licenses have}} been transferred to your account`,

    'notification.empty.update': `Update was processed {br}
        but there was nothing to update, nothing is changed`,

    'notification.delete.success': `{item} {br}
        has been deleted successfully`,

    'notification.delete.class-code.success': `Class Code has been deleted successfully`,

    'notification.archive.success': `{item} {br}
        has been archived successfully`,

    'notification.unarchive.success': `{item} {br}
        has been unarchived successfully`,

    'notification.status-change.success': `{item} status {br}
        has been updated successfully`,

    'notification.mass-students-change.success': `{count} {count, plural, one {student} other {students}} {br}
        {count, plural, one {has} other {have}} been updated successfully`,

    'notification.change-my-password.success': `Your password {br}
        has been updated successfully`,

    'notification.reset-my-password.success': `Check your email. We sent you a link to reset your password. {br}
        The link will expire in 48 hours.`,

    'notification.set-my-password.success': `Password has been reset successfully {br}
        You may now login with the new password`,

    'notification.set-my-profile-username.success': `Username has been updated successfully {br}
        You may now login with the new username`,

    'notification.resend-activation-link.success': `Activation link for {item} {br}
        has been sent successfully`,

    'notification.bulk-upload.archive-not-affected-warning': `Make sure you want to archive not affected students.
        The archive action will not verify relation between educators and students.
        Only chosen school matters.`,

    'notification.educators.manage-individual': `Note: to manage Purchase Order use Purchases tab`,

    'notification.teams.manage-site': `Note: To add a new school year or upgrade number of students use Purchase Order`,

    'notification.purchase-order.not-historical': `Note: Non-Historical Purchase
        will add Individual Licenses Quantity to the educator's account`,

    'notification.mass-individual.success': `{assigned} {assigned, plural, one {License has been} other {Licenses have been}}
        activate/extend successfully`,

    'notification.mass-individual.warning': `Activate/Extend License is completed with: {br}
        Licenses Activated/Extended: {assigned} {br}
        Students Failed: {failed}`,

    'notification.mass-individual.switch-default-program': `The student(s) listed below
        did not have an active program, so we activated one based on their grade level.
        If you want to activate a different program, you can switch now or do so later.`,

    'notification.select.limit.exceeded': `Number of selected rows has been limited to {limit} {br}
        Not all rows from the list were selected`,

    'notification.no-more-account-sources-to-verify': 'There are no more accounts pending verification',

    'notification.validation.failed': 'Data validation failed',

    'notification.acting.as.user.not.found': `You can not act as requested user. Possible reasons: {br}
        - user does not have active account (Educator, Parent) {br}
        - user does not have valid license (Student) {br}
        - user is not found {br}
        - user is another Uber.`,

    'notification.http.5xx': `Oops, something went wrong! We are already notified about the issue {br}
        We will fix it as soon as possible`,

    'notification.http.401': `Your session has expired, please login again`,

    'notification.http.403': `You don't have permission to access this resource {br}
        Log out and log back to try again`,

    'notification.http.404': `The resource you requested can not be found`,

    'notification.play.http.403': form['form.validation.license-not-found'],

    'notification.http.4xx': `Oops, something went wrong! {br}
        Refresh the page or try again later`,

    'notification.http.unknown': `Oops, something went wrong! {br}
        Check your internet connection and try again`,

    'notification.subscribe.failed': `You are not fully connected to sidebar notifications {br}
        Refresh the page or log out and log back to try again`,

    'notification.assign-program.rollback.success': `Assigned License has been rolled back successfully`,

    'notification.unlock-assessment.success': 'Assessment is unlocked {br} for {count} {count, plural, one {student} other {students}}',
    'notification.unlock-assessment.already-unlocked': `Assessment for {username} is not unlocked
        because current Assessment has not been completed`,
    'notification.unlock-assessment.limit-reached': `Assessment for {username} is not unlocked
        because Assessment reached it's iterations limit`,

    'notification.restart-game.success': 'Game has been restarted {br} for {count} {count, plural, one {student} other {students}}',

    'notification.transfer-licenses.success': 'Licenses transferred successfully',

    'notification.site-license-grant-access.success': 'Access granted successfully',
    'notification.site-license-grant-access.failed': 'Site license not found',
    'notification.site-license-revoke-access.success': 'Access revoked successfully',
    'notification.site-license-revoke-access.failed': 'Site license not found',

    'notification.receipt-link.not-found': 'Selected receipt is not available',

    'notification.payment.payment-gateway-unavailable': 'The payment gateway is temporarily unavailable, please try again later',
    'notification.on-page.payment-canceled': 'Your payment was canceled',
    'notification.on-page.purchase-success': `Your purchase was completed successfully`,
    'notification.on-page.payment-success': `Your payment was completed successfully. {br}
        {quantity} {quantity, plural, one {license} other {licenses}} have been added to your account`,
    'notification.on-page.payment-unknown': `Payment status unknown. We are working on the issue,
        and we will email you as soon as it is resolved`,

    'notification.on-page.reset-password-link-invalid': 'Password reset link has expired or was already used',
    'notification.on-page.profile-username-link-invalid': 'Username update link has expired or was already used',
    'notification.on-page.account-activation-link-invalid': 'The activation link you provided is not valid',
    'notification.on-page.account-created': `Please check for your activation email,
        and if you do not see it, please check your spam folder.`,
    'notification.on-page.is-awaiting-activation': `You previously requested an account but have not completed the activation step.
        We are resending an activation email now.
        Please check your spam folder if you do not see the email shortly.`,
    'notification.on-page.is-activated': 'Great news! You already have an account and can log in immediately',

    'notification.on-page.empty-library': 'Your library is empty',

    'notification.login.dashboard.subdomain': 'Educators and Parents login  to the Centervention Dashboard {br} at {url}',

    'notification.transfer-students.many-schools': `Selected students attend to more than one school. {br}
        Limit selected students to a single school to continue.`,

    'notification.classlink.login.failed': `It looks like your ClassLink account is not linked to your Centervention account.
        Ask your teacher or adult for help.`,
    'notification.clever.login.failed': `It looks like your Clever account is not linked to your Centervention account.
        Ask your teacher or adult for help.`,

    'notification.report.not.found': `Report not found`,
};

export default notification;
