const uber = {
    changePassword: 'uber/change-password',
    actingAs: 'uber/acting-as',
    account: {
        deactivate: 'uber/account/deactivate',
        rollbackDeactivate: 'uber/account/rollback-deactivate',
    },
    activityHistory: 'uber/activity-history',
    broadcasting: {
        auth: 'uber/broadcasting/auth',
        subscribed: 'uber/broadcasting/subscribed',
    },
    ecommerce: {
        ecommerceHistory: 'uber/ecommerce/history',
        siteLicensesHistory: 'uber/site-licenses/history',
        siteLicensesOfTeam: 'uber/site-licenses-of-team',
    },
    accountSources: {
        accountSources: 'uber/account-sources',
        form: 'uber/account-sources/form',
        referral: 'uber/account-sources/referral',
        uber: 'uber/account-sources/uber',
        resend: {
            form: 'uber/account-sources/resend/form',
            referral: 'uber/account-sources/resend/referral',
            uber: 'uber/account-sources/resend/uber',
        },
    },
    districts: {
        districts: 'uber/districts',
        ofEducatorList: 'uber/districts-of-educator-list',
    },
    schools: {
        schools: 'uber/schools',
        ofEducator: 'uber/schools-of-educator',
        ofAdmin: 'uber/schools-of-admin',
        ofLog: 'uber/schools-of-log',
    },
    teams: {
        teams: 'uber/teams',
        ofEducator: 'uber/teams-of-educator',
        ofLog: 'uber/teams-of-log',
    },
    educators: {
        educators: 'uber/educators',
        ofSchool: 'uber/educators-of-school',
        ofLog: 'uber/educators-of-log',
        ofStudentActive: 'uber/educators-of-student',
        ofStudentArchived: 'uber/educators-of-student',
        ofAdmin: 'uber/educators-of-admin',
        adminsOfTeam: 'uber/admins-of-team',
        membersOfTeam: 'uber/members-of-team',
        source: {
            uber: 'uber/educators/from-uber',
            verify: 'uber/educators/verify',
        },
        convertToParent: 'uber/educators/convert-to-parent',
        reachedOut: 'uber/educators/reached-out',
        highPriority: 'uber/educators/high-priority',
    },
    quote: {
        add: 'uber/quote',
        addUnregisteredUser: 'uber/quote-unregistered-user',
        view: 'uber/quote-view',
        send: 'uber/quote-send',
    },
    printableReports: {
        siteLicenseUsage: 'uber/printable-reports/site-license-usage',
        siteLicenseUsagePerSchool: 'uber/printable-reports/site-license-usage-per-school',
    },
    students: {
        students: 'uber/students',
        archive: 'uber/students/archive',
        unarchive: 'uber/students/unarchive',
        delete: 'uber/students/delete',
        active: 'uber/students-active',
        archived: 'uber/students-archived',
        ofEducator: 'uber/students-of-educator',
        ofEducatorList: 'uber/students-of-educator-list',
        ofEducatorSchools: 'uber/students-of-educator-schools',
        ofLog: 'uber/students-of-log',
        validLicenseOfEducator: 'uber/students-valid-license-of-educator',
        activeOfEducator: 'uber/students-active-of-educator',
        archivedOfEducator: 'uber/students-archived-of-educator',
        bulkUpload: 'uber/students-active/bulk-upload',
        bulkUploadRows: 'uber/students-active/bulk-upload-rows',
        massArchive: 'uber/students/mass-archive',
        massUnarchive: 'uber/students/mass-unarchive',
        massGrade: 'uber/students/mass-grade',
        massTeacher: 'uber/students/mass-teacher',
        massPassword: 'uber/students/mass-password',
        massResetPassword: 'uber/students/mass-reset-password',
        massTransfer: 'uber/students/mass-transfer',
        assignProgramHistory: 'uber/students/assign-program-history',
        assignProgramRollback: 'uber/students/assign-program-rollback',
        cloneIntoSandbox: 'uber/students/clone-into-sandbox',
        toggleGameDebugMode: 'uber/students/game-debug-mode',
    },
    purchases: {
        purchaseOverview: 'uber/purchase-overview',
        purchaseHistory: 'uber/purchase-history',
        purchaseOrders: 'uber/purchase-orders',
        purchases: {
            site: 'uber/purchases/site',
        },
    },
    parents: {
        parents: 'uber/parents',
        ofChildActive: 'uber/parents-of-child',
        ofChildArchived: 'uber/parents-of-child',
        convertToEducator: 'uber/parents/convert-to-educator',
    },
    children: {
        children: 'uber/children',
        active: 'uber/children-active',
        activeOfParent: 'uber/children-active-of-parent',
        archived: 'uber/children-archived',
        archivedOfParent: 'uber/children-archived-of-parent',
    },
    metrics: {
        educatorMetrics: 'uber/metrics/educators',
        adminMetrics: 'uber/metrics/admins',
        adminLiveMetrics: 'uber/metrics/live-admins',
        outOfLicense: 'uber/metrics/out-of-license',
        giftsInviteDetails: 'uber/metrics/gifts-invite-details',
        giftsInviteAggregated: 'uber/metrics/gifts-invite-aggregated',
        accessOfEducator: 'uber/metrics/access-of-educator',
    },
    admin: {
        lessons: 'uber/lessons',
    },
    autocomplete: {
        searchUsersBar: 'uber/search-users/autocomplete',
        districts: 'uber/districts/autocomplete',
        districtsReversed: 'uber/districts/autocomplete-reversed',
        districtsReversedPrefix: 'uber/districts/autocomplete-reversed-prefix',
        schools: 'uber/schools/autocomplete',
        schoolsReversed: 'uber/schools/autocomplete-reversed',
        schoolsOfDistrict: 'uber/schools-of-district/autocomplete',
        schoolsOfEducator: 'uber/schools-of-educator/autocomplete',
        admins: 'uber/admins/autocomplete',
        adminsReversed: 'uber/admins/autocomplete-reversed',
        educators: 'uber/educators/autocomplete',
        parents: 'uber/parents/autocomplete',
        educatorsOfSchool: 'uber/educators-of-school/autocomplete',
        educatorsOfDistrict: 'uber/educators-of-district/autocomplete',
        educatorsWithNotActive: 'uber/educators-with-not-active/autocomplete',
        educatorsWithNotActiveOfSchool: 'uber/educators-with-not-active-of-school/autocomplete',
        educatorsWithNotActiveOfDistrict: 'uber/educators-with-not-active-of-district/autocomplete',
        teams: 'uber/teams/autocomplete',
        purchaseSite: 'uber/purchases/site/autocomplete',
        teamParticipantsOfDistrict: 'uber/team-participants-of-district/autocomplete',
        studentsWithArchived: 'uber/students-with-archived/autocomplete',
        accountsDeactivated: 'uber/accounts-deactivated/autocomplete',
    },
    academy: {
        active: 'uber/academy/students-active',
        archived: 'uber/academy/students-archived',
        report: 'uber/academy/report',
        timePlayed: 'uber/academy/time-played',
    },
    zoou: {
        active: 'uber/zoou/students-active',
        archived: 'uber/zoou/students-archived',
        assessment: 'uber/zoou/assessment',
        skillBuilder: 'uber/zoou/report',
        skillBuilderBoard: 'uber/zoou/skill-builder-board',
        timePlayed: 'uber/zoou/time-played',
    },
    ssgrin: {
        active: 'uber/ssgrin/students-active',
        archived: 'uber/ssgrin/students-archived',
        report: 'uber/ssgrin/report',
        timePlayed: 'uber/ssgrin/time-played',
    },
    hoh: {
        active: 'uber/hoh/students-active',
        archived: 'uber/hoh/students-archived',
        assessment: 'uber/hoh/assessment',
        skillBuilder: 'uber/hoh/report',
        timePlayed: 'uber/hoh/time-played',
    },
    sim: {
        active: 'uber/sim/students-active',
        archived: 'uber/sim/students-archived',
        library: 'uber/sim/library',
        comic: 'uber/sim/comic',
        timePlayed: 'uber/sim/time-played',
    },
};

export default uber;
